import React from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import AnnLayout from './containers/AnnLayout';
import MomLayout from './containers/MomLayout';

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Quizzz!</div>,
    errorElement: <div>Error!</div>,
  },
  {
    path: "/mom",
    element: <MomLayout />,
    errorElement: <div>Error!</div>,
  },
  {
    path: "/ann",
    element: <AnnLayout />,
  },
]);

function Router() {
  return (
    <RouterProvider router={router} />
  )
}

export default Router
