import styled from '@emotion/styled';
import React from 'react';
import { ButtonStyled } from './shared-components';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  margin: 0 20px;
  text-align: center;
  margin: 0 20px;
  font-size: 25px;
  font-weight: 300;
  opacity: 0.8;
  white-space: break-spaces;
`

const Image = styled.img`
  height: 300px;
  margin-top: 20px;
  object-fit: contain;
`

interface IProps {
  text: string
  img: any
  canGoNext?: boolean
  onNext?: () => void
}

const Landing = ({text, img, canGoNext, onNext}: IProps) => {

  return (
    <Layout>
      <Header>{text}</Header>
      <Image src={img} alt='img'/>
      {canGoNext && (
        <ButtonStyled onClick={onNext}>Готовa</ButtonStyled>
      )}
    </Layout>
  );
}

Landing.defaultProps = {
  canGoNext: false,
  onNext: () => {},
}

export default Landing
