import styled from "@emotion/styled";

export const ButtonStyled = styled.div`
  background: #570861;
  color: white;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`