import styled from '@emotion/styled';
import { useState } from 'react';
import Info from '../components/Info';
import Landing from '../components/Landing';
import Quizz, { IQuestion } from '../components/Quizz';
import KaramelImage from '../static/karamelka.png'
import KompotImage from '../static/kompot.png'

const RootLayout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
`

const questions: IQuestion[] = [
  {
    questionText: 'Он зеленый и зубастый\nОчень хищный и опасный\nПять лягушек проглотил\nЭтот злобный ...', 
    questionImg: null, 
    answer: 'крокодил',
   },
  { questionText: 'Спит всю зиму напролет,\nЛапу нехотя сосет\nЛюбит мед, малину, шишки,\nЭто старый добрый...', questionImg: null, answer: 'мишка' },
  { questionText: 'Эта рыжая плутовка\nОбхитрит любого ловко.\nХвост красивый, как коса,\nЭто хитрая ...', questionImg: null, answer: 'лиса' },
  { questionText: 'Как зовут моего брата?', questionImg: KompotImage, answer: 'компот' },
]

enum Step {
  Landing,
  Info,
  Quizz,
  Final
}

const AnnLayout = () => {

  const [step, setStep] = useState<Step>(Step.Landing)

  const renderStep = (step: Step) => {
    switch (step) {
      case Step.Landing:
        return <Landing canGoNext text='Привет, Аня! Я хочу сыграть с тобой в игру. Ты готова?' onNext={() => setStep(Step.Info)} img={KaramelImage}/>
      case Step.Info:
        return <Info text='Чтобы выиграть тебе нужно разгадать мои загадки. Жми Начать как будешь готова' onNext={() => setStep(Step.Quizz)} img={KaramelImage}/>
      case Step.Final:
        return <Landing text={'Псоледняя загадка подскажет где тайник!\n\nСоберу рубашки, майки\nИ скажу ей: «Постирай-ка!\nВ барабане покрути,\nБелье чистым возврати!»'} img={KaramelImage}/>
      default:
        return <Quizz inputPlaceholder="Ответ" questions={questions} backgroundImg={KaramelImage} onFinish={() => setStep(Step.Final)}/>
    }
  }

  return (
    <RootLayout>
      {renderStep(step)}
    </RootLayout>
  );
}

export default AnnLayout
