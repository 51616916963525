import styled from '@emotion/styled';
import { useState } from 'react';
import Info from '../components/Info';
import Landing from '../components/Landing';
import Quizz, { IQuestion } from '../components/Quizz';
import SawImage from '../static/saw.png'
import IgorImage from '../static/igor.png'
import OsImage from '../static/oss.png'

const RootLayout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
`

const questions: IQuestion[] = [
  {
    questionText: 'В брюшке — баня,\nВ носу — решето,\nНос — хоботок,\nНа голове — пупок,\nВсего одна рука без пальчиков,\nИ та — на спине Калачиком.', 
    questionImg: null, 
    answer: 'o112',
   },
  { questionText: '', questionImg: OsImage, answer: '0302' },
  { questionText: 'Без языка живет,\nНе ест и не пьет,\nА говорит и поет.', questionImg: null, answer: '212k' },
  { questionText: '', questionImg: IgorImage, answer: 'im00' },
  { questionText: 'В этом маленьком предмете\nПоселился тёплый ветер.', questionImg: null, answer: '5557' },
  { questionText: 'Носить воду в ИКС - проводить время в пустую. ИКСа у нас нет, но кое-что для макарон, например, есть у всех', questionImg: null, answer: '44d2' },
]

enum Step {
  Landing,
  Info,
  Quizz,
  Final
}

const MomLayout = () => {

  const [step, setStep] = useState<Step>(Step.Landing)

  const renderStep = (step: Step) => {
    switch (step) {
      case Step.Landing:
        return <Landing canGoNext text='Привет, Мама! Я хочу сыграть с тобой в игру. Ты готова?' onNext={() => setStep(Step.Info)} img={SawImage}/>
      case Step.Info:
        return <Info text='Чтобы выиграть тебе нужно ответить на несколько вопросов. Ответ на вопрос укажет тебе место, где ты найдешь код, которыйн ужно использовать чтобы пройти дальше. Жми Начать как будешь готова' onNext={() => setStep(Step.Quizz)} img={SawImage}/>
      case Step.Final:
        return <Landing text='Собери из букв в ответах слово (русское). Оно укажет где тайник.' img={SawImage}/>
      default:
        return <Quizz questions={questions} backgroundImg={SawImage} onFinish={() => setStep(Step.Final)}/>
    }
  }

  return (
    <RootLayout>
      {renderStep(step)}
    </RootLayout>
  );
}

export default MomLayout
