import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { ButtonStyled } from './shared-components';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  margin: 0 20px;
  text-align: center;
  margin: 0 20px;
  font-size: 25px;
  font-weight: 300;
  opacity: 0.8;
  white-space: break-spaces;
  margin-bottom: 20px;
`

const Image = styled.img`
  height: 300px;
  object-fit: contain;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const Input = styled.input`
  font-size: 20px;
  margin: 10px auto;
  width: 85%;
  border: 1px solid lightgrey;
  padding: 10px 20px;
  border-radius: 10px;
`

const Error = styled.div`
  color: red;
  position: absolute;
  top: 56px;
  left: 10px;
`

const BackImage = styled.img`
  position: absolute;
  height: 300px;
  object-fit: contain;
  top: -12px;
  left: -101px;
  transform: rotate(90deg) scale(0.5);
`

export interface IQuestion {
  questionText: string
  questionImg: any
  answer: string
}


interface IProps {
  questions: IQuestion[]
  backgroundImg: any
  inputPlaceholder?: string
  onFinish: () => void
}

const Quizz = ({questions, backgroundImg, inputPlaceholder, onFinish}: IProps) => {

  const [questionNumber, setQuestionNumber] = useState(0)
  const [answer, setAnswer] = useState('')
  const [error, setError] = useState('')
  const question = questions[questionNumber]

  const onNext = useCallback(() => {
    if (questions[questionNumber].answer.toLowerCase() === answer.toLowerCase()) {
      if (questionNumber === questions.length - 1) {
        onFinish()
      } else {
        setQuestionNumber((n) => n + 1)
        setAnswer('')
        setError('')
      }
    } else {
      setError('неверно')
    }
    
  }, [answer, questionNumber])

  return (
    <Layout>
      <BackImage src={backgroundImg} alt="bImage"/>
      {question.questionText && (
        <Header>{question.questionText}</Header>
      )}
      {question.questionImg && (
        <Image src={question.questionImg} alt='img'/>
      )}
      <InputContainer>
        <Input value={answer} onChange={(ev) => {
          setAnswer(ev.target.value)
          setError('')
        }} placeholder={inputPlaceholder}/>
        {error && (
          <Error>{error}</Error>
        )}
      </InputContainer>
      <ButtonStyled onClick={onNext}>OK</ButtonStyled>
    </Layout>
  );
}

Quizz.defaultProps = {
  inputPlaceholder: 'Код',
}

export default Quizz
